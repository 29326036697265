import React from 'react'
import PropTypes from 'prop-types'

import layoutQuery from "../hooks/use-layout-query"

import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'
import Loading from '../components/loading'

const Layout = ({ children, location, pageContext }) => {
  const data = layoutQuery()

  const windowGlobal = typeof window !== 'undefined' && window

  // check local storage for animation
  const animation = windowGlobal.localStorage?.getItem('animation')
  const [showAnimation, setShowAnimation] = React.useState(!animation)

  React.useEffect(() => {
    setTimeout(() => {
      setShowAnimation(false)
    }, 5000)
  }, [])

  return (
    <>
      <Loading />
      <Header location={location} data={data} pageContext={pageContext} showAnimation={showAnimation} />
      <main className={showAnimation ? 'hide' : 'finished'}>
        { children }
        <Footer data={data} pageContext={pageContext} />
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
