import React from 'react'
import Umbrella from '../assets/animations/umbrella.json'
import UmbrellaMobile from '../assets/animations/umbrella-mobile.json'
import Lottie from 'react-lottie'

const Loading = () => {

  const [stopped, setStopped] = React.useState(true)
  const [hide, setHide] = React.useState(false)
  const [mobile, setMobile] = React.useState(typeof window !== 'undefined' && window.innerWidth < 900)

  const umbrella = {
    loop: false,
    autoplay: false,
    animationData: mobile ? UmbrellaMobile : Umbrella,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      setStopped(false)
    }, 500)
    setTimeout(() => {
      setHide(true)
    }, 5000)
  }, [])

  return (
    <section className={`loading${hide ? ' loading--hide' : ''}`}>
      <Lottie
        options={umbrella}
        isClickToPauseDisabled={true}
        isStopped={stopped}
      />
    </section>
  )
}

export default Loading