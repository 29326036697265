import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo, Phone, Facebook, Instagram, LinkedIn, UmbrellaAboutMobile, UmbrellaAbout, Divorce, FamilyViolence, InternationalRelocation, BindingFinancialAgreements, Parenting, PropertySettlement, ParentingCoordination, Negotiation, GuardianshipAdministration, RightArrow, CurlyBackArrow, FamilySupport } from './icons'

class Header extends Component {

  state = {
    offCanvas: false, 
    scrolled: false, 
    direction: 0, 
    prevDirection: 0, 
    prevScroll: 0,
    services: false,
    servicesOffCanvas: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = (e) => {
    let { direction, prevDirection, prevScroll, scrolled } = this.state

    const curScroll = window.scrollY || document.scrollTop
    scrolled = curScroll > 1

    // Make sure they scroll more than 5px
    if (Math.abs(prevScroll - curScroll) <= 5) return
    direction = curScroll > prevScroll ? 2 : 1
    
    prevScroll = curScroll

    requestAnimationFrame(() => {
      this.setState({
        scrolled, direction, prevDirection, prevScroll,
      })
    })
  }

  scrollDetect = () => {
    window.clearTimeout(this.isScrolling)
    this.isScrolling = setTimeout(this.onScroll, 0)
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false, services: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled, services, servicesOffCanvas, direction } = this.state

    let headerClass = 'header'
    if ( this.props.pageContext?.header === 'White' ) headerClass += ' header--white'
    if ( this.props.showAnimation ) headerClass += ' header--animation'
    if ( services ) headerClass += ' header--services'
    if (scrolled) headerClass += ' header--scrolled'
    if (direction === 2) headerClass += ' header--hidden'
    if (offCanvas) headerClass += ' header--off-canvas'

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='Umbrella Family Law' className='header__logo' {...props}>
              <Logo />
            </Link>
            <nav className='header__nav'>
              <ul>
                <li className='header__services' onMouseEnter={() => this.setState({ services: true })} onMouseLeave={() => this.setState({ services: false })}>
                  <span>Our Services</span>
                  <div className='header__dropdown'>
                    <div className='header__dropdown-wrapper'>
                      <ul>
                        <li>
                          <div className='icon'>
                            <Divorce />
                          </div>
                          <Link to='/service/divorce/' {...props}>
                            Divorce
                            <RightArrow />
                          </Link>
                        </li>
                        <li>
                          <div className='icon'>
                            <FamilyViolence />
                          </div>
                          <Link to='/service/family-violence/' {...props}>
                            Family Violence
                            <RightArrow />
                          </Link>
                        </li>
                        <li>
                          <div className='icon'>
                            <InternationalRelocation />
                          </div>
                          <Link to='/service/international-relocation/' {...props}>
                            International Relocation
                            <RightArrow />
                          </Link>
                        </li>
                        <li>
                          <div className='icon'>
                            <BindingFinancialAgreements />
                          </div>
                          <Link to='/service/financial-agreements/' {...props}>
                            Binding Financial Agreements (Prenup)
                            <RightArrow />
                          </Link>
                        </li>
                        <li>
                          <div className='icon'>
                            <Parenting />
                          </div>
                          <Link to='/service/parenting/' {...props}>
                            Parenting
                            <RightArrow />
                          </Link>
                        </li>
                        <li>
                          <div className='icon'>
                            <PropertySettlement />
                          </div>
                          <Link to='/service/property-settlement/' {...props}>
                            Property Settlement
                            <RightArrow />
                          </Link>
                        </li>
                        <li>
                          <div className='icon'>
                            <Negotiation />
                          </div>
                          <Link to='/service/negotiation/' {...props}>
                            Negotiation
                            <RightArrow />
                          </Link>
                        </li>
                                        <li>
                          <div className='icon'>
                            <ParentingCoordination />
                          </div>
                          <Link to='/service/parenting-coordination' {...props}>
                            Parenting Co-ordination
                            <RightArrow />
                          </Link>
                        </li>
                        <li>
                          <div className='icon'>
                            <GuardianshipAdministration />
                          </div>
                          <Link to='/service/guardianship-administration/' {...props}>
                            Guardianship Administration
                            <RightArrow />
                          </Link>
                        </li>
                        <li>
                          <div className='icon'>
                            <FamilySupport />
                          </div>
                          <Link to='/service/familysupport' {...props}>
                            Family Support
                            <RightArrow />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className='header__dropdown-umbrella'>
                      <UmbrellaAbout color='#F6D563' />
                    </div>
                  </div>
                </li>
                <li><Link to='/team/' {...props}>The Team</Link></li>
                <li><Link to='/about-us/' {...props}>About Us</Link></li>
                <li><Link to='/insights/' {...props}>Blogs</Link></li>
                <li><Link to='/careers/' {...props}>Join Us</Link></li>
                <li><Link to='/existing-client/' {...props}>Existing Clients</Link></li>
                <li><Link to='/resources/' {...props}>Resources</Link></li>
              </ul>
            </nav>
            <div className='header__contact'>
              <Link to='tel:+61392796800'>
                <Phone />
              </Link>
              <Link className='btn btn--yolk' to='/get-started/'>Get Started</Link>
              <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                <span className='lines'></span>
              </button>
            </div>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <div className='off-canvas__main'>
              <Link className='btn' to='/get-started/'>Get Started</Link>
              <nav className='off-canvas__nav'>
                <ul>
                  <li><button to='/service/' onClick={() => this.setState({ servicesOffCanvas: true })}>Our Services</button></li>
                  <li><Link to='/team/' {...props}>The Team</Link></li>
                  <li><Link to='/about-us/' {...props}>About Us</Link></li>
                  <li><Link to='/insights/' {...props}>Blogs</Link></li>
                  <li><Link to='/careers/' {...props}>Join Us</Link></li>
                  <li><Link to='/existing-client/' {...props}>Existing Clients</Link></li>
                  <li><Link to='/resources/' {...props}>Resources</Link></li>
                  <li><Link to='/contact-us/' {...props}>Contact</Link></li>
                </ul>
              </nav>
              <div>
                <p>142-144 Albert Road<br />
                South Melbourne VIC 3205</p>
                <p><Link to='mailto:theteam@umbrellafamilylaw.com.au'>theteam@umbrellafamilylaw.com.au</Link><br />
                <Link to='tel:+61392796800'>03 9279 6800</Link></p>
              </div>
              <ul className='social'>
                <li><Link to='https://www.facebook.com/umbrellafamilylaw/'><Facebook /></Link></li>
                <li><Link to='https://www.linkedin.com/company/umbrella-family-law/'><LinkedIn /></Link></li>
                <li><Link to='https://www.instagram.com/umbrellafamilylaw/'><Instagram /></Link></li>
              </ul>
            </div>
            <div className={`off-canvas__services${servicesOffCanvas ? ' active' : ''}`}>
              <button className='off-canvas__back' onClick={() => this.setState({ servicesOffCanvas: false })}>
                <CurlyBackArrow />
                <span>Back to Main</span>
              </button>
              <ul>
                <li>
                  <Link to='/service/divorce/' {...props}>
                    Divorce
                  </Link>
                </li>
                <li>
                  <Link to='/service/family-violence/' {...props}>
                    Family Violence
                  </Link>
                </li>
                <li>
                  <Link to='/service/international-relocation/' {...props}>
                    International Relocation
                  </Link>
                </li>
                <li>
                  <Link to='/service/financial-agreements/' {...props}>
                    Binding Financial Agreements
                  </Link>
                </li>
                <li>
                  <Link to='/service/parenting/' {...props}>
                    Parenting
                  </Link>
                </li>
                <li>
                  <Link to='/service/property-settlement/' {...props}>
                    Property Settlement
                  </Link>
                </li>
                <li>
                  <Link to='/service/negotiation/' {...props}>
                    Negotiation
                  </Link>
                </li>
                <li>
                  <Link to='/service/parenting-coordination/' {...props}>
                    Parenting Co-ordination
                  </Link>
                </li>
                <li>
                  <Link to='/service/guardianship-administration/' {...props}>
                    Guardianship Administration
                  </Link>
                </li>
                <li>
                  <Link to='/service/familysupport' {...props}>
                    Family Support
                  </Link>
                </li>
              </ul>
              <Link className='btn' to='/get-started/'>Get Started</Link>
            </div>
          </div>
          <div className='off-canvas__umbrella'>
            <UmbrellaAboutMobile color='#F6D563' />
          </div>
        </div>
      </>
    )
  }
}

export default Header
