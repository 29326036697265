import React from 'react'
import Link from '../utils/link'
import parse from 'html-react-parser'
import Flags from '../assets/images/flags.png'
import { FooterLogo, Umbrella, UmbrellaMobile, Facebook, LinkedIn, Instagram } from './icons'

const Footer = ({ pageContext }) => {
  return (
    <footer className={`footer footer--${pageContext.shape}`}>
      <div className='footer__umbrella footer__umbrella--desktop'><Umbrella /></div>
      <div className='footer__umbrella footer__umbrella--mobile'><UmbrellaMobile /></div>
      <div className='footer__inner'>
        <div className='footer__cta'>
          { pageContext.title && <h4>{parse(pageContext.title)}</h4> }
          { pageContext.content && 
            <div>
              {parse(pageContext.content)}
              <Link to='/get-started/' className='btn btn--yolk'>Get started now</Link>
            </div> 
          }
        </div>
        <div className='footer__sections'>
          <div className='footer__top'>
            <Link to='/' title='Umbrella Family Law' className='footer__logo'>
              <FooterLogo />
            </Link>
            <ul>
              <li><Link to='/about-us/'>About Us</Link></li>
              <li><Link to='/team/'>Our Team</Link></li>
              <li><Link to='/insights/'>Blogs</Link></li>
              <li><Link to='/resources/'>Resources</Link></li>
              <li><Link to='/contact-us/'>Contact Us</Link></li>
              
            </ul>
            <div>
              <p>142-144 Albert Road<br />
              South Melbourne VIC 3205</p>
              <p><Link to='mailto:theteam@umbrellafamilylaw.com.au'>theteam@umbrellafamilylaw.com.au</Link><br />
              <Link to='tel:+61392796800'>03 9279 6800</Link></p>
            </div>
            <ul className='footer__social'>
              <li><Link to='https://www.facebook.com/umbrellafamilylaw/'><Facebook /></Link></li>
              <li><Link to='https://www.linkedin.com/company/umbrella-family-law/'><LinkedIn /></Link></li>
              <li><Link to='https://www.instagram.com/umbrellafamilylaw/'><Instagram /></Link></li>
            </ul>
          </div>
          <div className='footer__middle'>
            <p>© Umbrella Family Law, {new Date().getFullYear()}</p>
            <div>
              <ul className='footer__social'>
                <li><Link to='https://www.facebook.com/umbrellafamilylaw/'><Facebook /></Link></li>
                <li><Link to='https://www.linkedin.com/company/umbrella-family-law/'><LinkedIn /></Link></li>
                <li><Link to='https://www.instagram.com/umbrellafamilylaw/'><Instagram /></Link></li>
              </ul>
              <p>Design by <Link to='https://atollon.com.au/'>Atollon</Link></p>
            </div>
          </div>
          <div className='footer__bottom'>
            <div className='footer__bottom-left'>
              <p>All information on this site is of a general nature only and is not intended to be relied upon as, nor to be a substitute for, specific legal professional advice. No responsibility for the loss occasioned to any person acting on or refraining from action as a result of any material published can be accepted and we encourage you to contact our office for advice specific to your situation.</p>
            </div>
            <div className='footer__bottom-right'>
              <p>All of our staff members work inclusively with people of all gender identities, sexual orientation and CALD groups.</p>
              <img src={Flags} alt='Umbrella Flags' />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
